$(document).ready(function () {

    $('#navbarSupportedContent').on('show.bs.collapse', function () {
        $('.hamburger').addClass('is-active');
    });
    $('#navbarSupportedContent').on('hidden.bs.collapse', function () {
        $('.hamburger').removeClass('is-active');
    });

    carouselNormalization();
    $(window).resize(function () {
        carouselNormalization();
    });


    $('#navbarSupportedContent').on('show.bs.collapse', function () {
        $('body').addClass('static');
    })
    $('#navbarSupportedContent').on('hidden.bs.collapse', function () {
        $('body').removeClass('static');
    })

    if ($('html.tv').length > 0) {
        $('.jp-jplayer').each(function () {
            var el_id = $(this).attr('id');
            var el_arr = el_id.split('_');
            var id = el_arr[el_arr.length - 1];
            $("#" + el_id).jPlayer({
                ready: function () {
                    $(this).jPlayer("setMedia", {
                        mp3: "/music/" + id + ".mp3",
                    }); //.jPlayer("play");
                },
                swfPath: "/theme/js/jPlayer/dist/jplayer",
                supplied: "mp3",
                cssSelectorAncestor: "#jp_interface_" + id
            })
                .bind($.jPlayer.event.play, function () { // Using a jPlayer event to avoid both jPlayers playing together.
                    $(this).jPlayer("pauseOthers");
                });

        });
    }
});


// Set all carousel items to the same height
function carouselNormalization() {

    window.heights = [], //create empty array to store height values
        window.tallest; //create variable to make note of the tallest slide

    function normalizeHeights() {
        window.tallest = 0, window.heights.length = 0; //reset vars
        $('#carouselTestimonals .carousel-item').each(function () {
            $(this).css('min-height', '0'); //reset min-height
        });
        jQuery('#carouselTestimonals .carousel-item').each(function () { //add heights to array
            window.heights.push(jQuery(this).outerHeight());
        });
        window.tallest = Math.max.apply(null, window.heights); //cache largest value
        $('#carouselTestimonals .carousel-item').each(function () {
            $(this).css('min-height', tallest + 'px');
        });
    }

    normalizeHeights();


}